var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.componentsData, function (item, index) {
      return _c(
        "div",
        { key: index, ref: item.name, refInFor: true },
        [
          item.isShow
            ? _c("card-detail", {
                attrs: { detailInfo: _vm.detailData, type: item.name },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", { staticStyle: { "font-size": "17px" } }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "main",
                      fn: function () {
                        return [
                          _c(item.name, {
                            tag: "component",
                            attrs: { detailInfo: _vm.detailData },
                            on: {
                              scrollToTarget: _vm.scrollToTarget,
                              upSuccess: _vm.getDetailOrder,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }