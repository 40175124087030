const permission = {
  methods: {
    /**
     * 对当前账号是否有对应的权限做判断
     * 判断对应流程步骤中的是否有对应的权限点
     * 参数中包含流程状态 orderStatus， 审核状态 approveStatus
     */
    isPermission(data, customer) {
      const myPermission = JSON.parse(localStorage.getItem('userPermission'));
      let flag = false;
      switch (data.order_status || data.orderStatus) {
        case 'DQS':
          myPermission.includes('contract:signing') ? (flag = true) : (flag = false);
          break;
        case 'DFH':
          if (customer == 'buyder') break;
          if ((data.approveStatus || data.tprocAudit.approveStatus) == '1') {
            myPermission.includes('deliver:examine') ? (flag = true) : (flag = false);
            break;
          }
          myPermission.includes('deliver:upload') ? (flag = true) : (flag = false);
          break;
        case 'DSH':
          if (customer == 'sellder') break;
          if ((data.approveStatus || data.tprocAudit.approveStatus) == '1') {
            myPermission.includes('signfor:examine') ? (flag = true) : (flag = false);
            break;
          }
          myPermission.includes('signfor:upload') ? (flag = true) : (flag = false);
          break;
        case 'DKP':
          if (customer == 'buyder') break;
          if ((data.approveStatus || data.tprocAudit.approveStatus) == '1') {
            myPermission.includes('drawabill:examine') ? (flag = true) : (flag = false);
            break;
          }
          myPermission.includes('drawabill:upload') ? (flag = true) : (flag = false);
          break;
        case 'DZF':
          if (customer == 'sellder') break;
          if ((data.approveStatus || data.tprocAudit.approveStatus) == '1') {
            myPermission.includes('payment:examine') ? (flag = true) : (flag = false);
            break;
          }
          myPermission.includes('payment:upload') ? (flag = true) : (flag = false);
          break;
        case 'ZFC':
          myPermission.includes('collection:confirm') ? (flag = true) : (flag = false);
          break;
      }
      return flag;
    }
  }
};
export {permission};
