<template>
  <div>
    <div :ref="item.name" v-for="(item, index) in componentsData" :key="index">
      <card-detail v-if="item.isShow" :detailInfo="detailData" :type="item.name">
        <template #title>
          <span style="font-size: 17px;">{{ item.title }}</span>
        </template>
        <template #main>
          <!-- info是专门为uploadFiles传递的 -->
          <component :is="item.name" @scrollToTarget="scrollToTarget" @upSuccess="getDetailOrder" :detailInfo="detailData" />
        </template>
      </card-detail>
    </div>
  </div>
</template>
<script>
import {detailOrder, getBidOrderDetail} from '../../api';
import {permission} from '@/modules/mixin/permission-mixin.js';
export default {
  name: 'TransactionDetail',
  components: {
    cardDetail: () => import('@/modules/User/components/order/CardDetail.vue'),
    orderInformation: () => import('@/modules/User/components/order/OrderInformation.vue'),
    orderProcess: () => import('@/modules/User/components/order/OrderProcess.vue'),
    installment: () => import('@/modules/User/components/order/Installment.vue'),
    orderInternalDetail: () => import('@/modules/User/components/order/OrderInternalDetail.vue'),
    operationRecord: () => import('@/modules/User/components/order/OperationRecord.vue'),
    review: () => import('@/modules/User/components/order/Review.vue'),
    // 操作类组件
    uploadFiles: () => import('@/modules/User/components/order/operate/uploadFiles.vue') // 操作合集
  },
  mixins: [permission],
  data() {
    return {
      uploadFilesData: {},
      detailData: {
        orderInfo: {},
        payDeliverRateList: []
      },
      componentsData: [
        {
          title: '订单基本信息',
          name: 'orderInformation',
          isShow: true
        },
        {
          title: '订单流程',
          name: 'orderProcess',
          isShow: true
        },
        {
          title: '各种操作组件的集合', // 名字用js动态渲染
          name: 'uploadFiles',
          isShow: true
        },
        {
          title: '审核信息',
          name: 'review',
          isShow: true
        },
        {
          title: '分期付款/分期交货',
          name: 'installment',
          isShow: true
        },
        {
          title: '订单信息',
          name: 'orderInternalDetail',
          isShow: true
        },
        {
          title: '操作记录',
          name: 'operationRecord',
          isShow: true
        }
      ]
    };
  },
  created() {
    this.getDetailOrder();
  },
  mounted() {
    this.$bus.$on('updateDetails', () => this.getDetailOrder());
  },
  destroyed() {
    this.$bus.$off('updateDetails');
  },

  methods: {
    isShowCard() {
      let flag = false;
      if (this.$route.query.customer == 'sellder') {
        ['DFH', 'DKP'].includes(this.detailData.orderInfo.orderStatus) ? (flag = true) : '';
      } else {
        ['DSH', 'DZF'].includes(this.detailData.orderInfo.orderStatus) ? (flag = true) : '';
      }
      return flag;
    },
    getDetailOrder() {
      if (this.$route.query.orderType === 'BI') {
        this.getBiDetailOrder();
      } else {
        this.getAuDetailOrder();
      }
    },
    // 通过ref滚动页面
    scrollToTarget() {
      this.$refs.uploadFiles[0].scrollIntoView({behavior: 'smooth'});
    },
    // 在操作卡片中根据不同的状态显示不同的卡片的名字
    initializationState() {
      ['DQS'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '合同签署') : '';
      if (this.detailData.orderInfo.orderProcess === 'HK') {
        ['DFH'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '供应商发货') : '';
        ['DSH'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '买家签收') : '';
        ['DKP'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '供应商开发票') : '';
        ['DZF'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '买家支付') : '';
        ['ZFC'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData() : '';
        ['END'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData() : '';
      }
      if (this.detailData.orderInfo.orderProcess === 'KH') {
        ['DZF'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '买家支付') : '';
        ['DFH', 'DKP'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '供应商确认收款、发货、开发票') : '';
        ['DSH'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData('uploadFiles', '买家签收') : '';
        ['END'].includes(this.detailData.orderInfo.orderStatus) ? this.restructureData() : '';
      }
    },
    setOperateIsShow() {
      // 各种操作的组件初始化隐藏
      this.$set(this.componentsData[2], 'isShow', false);
      this.$set(this.componentsData[3], 'isShow', false);
      this.$set(this.componentsData[4], 'isShow', false);
      // 操作卡片是否显示
      //   if (this.$route.query.isHandleShow && ['DQS', 'DFWQ', 'DFH', 'DSH', 'DKP', 'DZF'].includes(this.detailData.orderInfo.orderStatus)) this.$set(this.componentsData[2], 'isShow', true);
      if (this.detailData.orderInfo.orderStatus === 'DQS' && this.$route.query.isHandleShow) this.$set(this.componentsData[2], 'isShow', true);
      if (this.$route.query.customer === 'buyder' && this.$route.query.isHandleShow) {
        switch (this.detailData.orderInfo.orderStatus) {
          case 'DSH':
            this.$set(this.componentsData[2], 'isShow', true);
            break;
          case 'DZF':
            this.$set(this.componentsData[2], 'isShow', true);
            break;
        }
      }
      if (this.$route.query.customer === 'sellder' && this.$route.query.isHandleShow) {
        switch (this.detailData.orderInfo.orderStatus) {
          case 'DFH':
            this.$set(this.componentsData[2], 'isShow', true);
            break;
          case 'DKP':
            this.$set(this.componentsData[2], 'isShow', true);
            break;
        }
      }
      // 审核卡片是否显示（mixin引入isPermission做权限判断）
      if (
        this.isPermission(this.detailData.orderInfo, this.$route.query.customer) &&
        this.isShowCard() &&
        (this.detailData.orderInfo.tprocAudit.approveStatus == '1' || this.detailData.orderInfo.tprocAudit.approveStatus == '3')
      )
        this.$set(this.componentsData[3], 'isShow', true);
      // 分期卡片是否显示
      if (this.detailData.orderInfo.isDeliveryStages || this.detailData.orderInfo.isPayStages) this.$set(this.componentsData[4], 'isShow', true);
    },
    restructureData(step, title) {
      this.componentsData.forEach(item => {
        if (item.name === step) {
          item.title = title;
        }
      });
    },
    // 如果是竞买/竞卖订单获取接口数据
    async getAuDetailOrder() {
      let res = await detailOrder({id: this.$route.query.orderid, customer: this.$route.query.customer});
      if (res.errno === 0) {
        this.detailData = res.data;
        this.detailData.orderInfo.contractStatus = res.data.contractInfo.contractStatus;
        // 初始化卡片的显示或隐藏
        this.setOperateIsShow();
        // 根据不同的状态显示不同的卡片的名字;
        this.initializationState();
      }
    },
    // 如果是竞标订单取接口数据
    async getBiDetailOrder() {
      let res = await getBidOrderDetail({id: this.$route.query.orderid, customer: this.$route.query.customer});
      if (res.errno === 0) {
        this.detailData.payDeliverRateList = res.data.payDeliverRateList;
        this.detailData.orderInfo = {
          contractStatus: res.data.contractInfo.contractStatus,
          orderSn: res.data.biOrderVo.orderSn,
          id: res.data.contractInfo.orderId,
          orderPrice: res.data.biOrderVo.orderPrice,
          clientInfoName: res.data.biOrderVo.userClientName,
          orderStatus: res.data.biOrderVo.orderStatus,
          myContractInfo: res.data.contractInfo.contractStatus,
          orderProcess: res.data.biOrderVo.orderProcess,
          payMode: res.data.biOrderVo.payMode,
          buyAddress: res.data.biOrderVo.address,
          buyClientName: res.data.biOrderVo.userClientName,
          buyUser: res.data.biOrderVo.consignee,
          buyTel: res.data.biOrderVo.mobile,
          sellClientName: res.data.biOrderVo.clientName,
          sellUser: res.data.biOrderVo.supConsignee,
          sellTel: res.data.biOrderVo.supMobile,
          sellAddress: res.data.biOrderVo.supAddress,
          pricRequire: res.data.biOrderVo.pricRequire,
          qualRequire: res.data.biOrderVo.qualRequire,
          delRequire: res.data.biOrderVo.delRequire,
          bidRequire: res.data.biOrderVo.bidRequire,
          isDeliveryStages: res.data.biOrderVo.isDeliveryStages,
          isPayStages: res.data.biOrderVo.isPayStages,
          payWay: res.data.biOrderVo.payWay,
          deliveryWay: res.data.biOrderVo.deliveryWay,
          tprocAudit: res.data.biOrderVo.tprocAudit,
          currentTerm: res.data.biOrderVo.currentTerm,
          currentTermStr: res.data.biOrderVo.currentTermStr
        };
        // 初始化卡片的显示或隐藏
        this.setOperateIsShow();
        // 根据不同的状态显示不同的卡片的名字;
        this.initializationState();
      }
    }
  }
};
</script>
